<template>
  <v-container style="max-width: 1000px">
    <v-row align="center" justify="center" class="head">
      <v-col cols="12">
        <div class="head-title mb-2 text-center">Dados da sua empresa</div>
        <div cols="12" class="subtitle text-center">Escolha o segmento que sua empresa está enquadrada</div>
      </v-col>
    </v-row>
    <v-form ref="form" @submit.prevent>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="12" class="mb-5"> Escolha o segmento </v-col>
        <v-row cols="10" v-if="loading">
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="n in 12" :key="n" class="mb-2">
            <v-skeleton-loader class="mx-auto" max-width="300" max-height="120px" type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="segmento in segmentos" :key="segmento.id" class="mb-2">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card class="pa-4 elevation-0 card-segmento" rounded hover outlined height="120px"
                  :elevation="hover ? 10 : 2" :class="{ 'segmentCard--active': empresa.segmento === segmento.slug }"
                  @click="empresa.segmento = segmento.slug">
                  <v-row no-gutters>
                    <div class="icon-checked" v-if="empresa.segmento === segmento.slug">
                      <v-icon color="success">mdi-check</v-icon>
                    </div>
                    <v-col cols="12">
                      <v-icon color="primary" size="28">{{ segmentoIcons[segmento.slug] }}</v-icon>
                    </v-col>
                    <v-col cols="12" class="text-subtitle-card mt-3" style="color: grey">
                      {{ segmento.nome }}
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
        <v-alert type="warning" :value="showValidateError" class="alert">
          É obrigatório selecionar um segmento
        </v-alert>
        <v-col cols="10">
          <v-row class="float-right mt-8 mb-15">
            <v-btn color="primary" @click="nextStep" :disabled="loading" rounded x-large>
              <span class="capitalize">Seguir</span>
              <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import cadastroService from '@/services/cadastro'
import { isNil } from 'lodash'
import cadastroMixin from './cadastroMixin'

export default {
  mixins: [validationMixin, cadastroMixin],
  validations: {
    usuario: {
      usuario: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      senha: {
        required,
        minLength: minLength(6),
      },
      cargo: {
        required,
      },
    },
  },
  data() {
    return {
      segmentoIcons: {
        'restaurantes-e-bares': 'mdi-food',
        comercio: 'mdi-cart-outline',
        'escritorio-administrativo': 'mdi-office-building-outline',
        ti: 'mdi-wifi',
        terceirizacao: 'mdi-shield-outline',
        educacao: 'mdi-school-outline',
        construcao: 'mdi-wrench-outline',
        'pequenos-negocios': 'mdi-store',
        industria: 'mdi-factory',
        agricultura: 'mdi-tractor',
      },
      empresa: {
        segmento: null,
      },
      loading: true,
      segmentos: [],
      showValidateError: false,
    }
  },
  computed: {
    ...mapGetters('cadastro', ['getEmpresa']),
  },
  methods: {
    ...mapMutations('cadastro', ['setEmpresa']),
    async getSegmentos() {
      try {
        this.loading = true
        const { data } = await cadastroService.getSegmentos()
        this.segmentos = data
      } catch (error) {
        this.showErrorOnSnackbar(error.response.data.message)
      } finally {
        this.loading = false
      }
    },
    validateSegmento() {
      this.showValidateError = isNil(this.empresa.segmento)
      return !this.showValidateError
    },
    async nextStep() {
      if (!this.validateSegmento()) return
      try {
        this.setEmpresa(this.empresa)
        this.actualStepWrapper = 5
        this.$router.push({ name: 'cadastro-faturamento' })
      } catch (error) {
        this.showErrorOnSnackbar(error.response.data.message)
      }
    },
  },
  mounted() {
    this.getSegmentos()
  },
  beforeMount() {
    this.actualStepWrapper = 4
    this.empresa = { ...this.getEmpresa }
  },
}
</script>
<style lang="scss">
.card-segmento {
  border: 1.5px solid #bfc0cd !important;
  border-radius: 16px;
  background-color: #fcfcfc !important;
}

.text-subtitle-card {
  font-weight: 500;
  font-size: 0.9rem;
}

.segmentCard {
  &--active {
    border-color: #006bf2 !important;
  }
}

.icon-checked {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 10px;
  margin-top: 5px;
}
</style>
