<template>
 <div>
  <div class="title text-center mb-4">INSTRUMENTO PARTICULAR DE CONTRATO DE PRESTAÇÃO DE SERVIÇOS</div>
  <p>
   Pelo presente instrumento, de um lado, na condição de <b>CONTRATADA</b>, LIMER GESTÃO DE SAÚDE CORPORATIVA – ME,
   pessoa jurídica de direito privado com sede na Av. Antônio Artioli, nº 570 – Swiss Park, Bloco A (ZUG), Sala 208,
   no Município de Campinas, Estado de São Paulo, CEP: 13049-900, inscrita no CNPJ sob n.º 24.809.516/0001-40, e de
   outro lado, a <b>CONTRATANTE</b>, devidamente qualificada quando de sua adesão na Plataforma Limer de titularidade
   da <b>CONTRATADA</b> (a “Plataforma”), resolvem firmar o presente Instrumento Particular de Contrato de Prestação
   de Serviços (o “Contrato”), o qual será regido pelas Cláusulas e condições a seguir mutuamente ajustadas:
  </p>
  <div class="clausula-title">CLÁUSULA PRIMEIRA – DO OBJETO</div>
  <p>
   1.1 A execução dos serviços voltados a saúde ocupacional, descritos no Plano de Serviços escolhido pela
   <b>CONTRATADA</b> (o “Plano de Serviços”), quando de sua adesão na Plataforma (os “Serviços”). A
   <b>CONTRATANTE</b> não poderá realizar a alteração durante a vigência do Contrato, do Plano de Serviços
   inicialmente escolhido.
  </p>
  <p>
   1.2 A execução dos Serviços abrange todas as cidades onde houver condições técnicas para o atendimento dos
   funcionários da <b>CONTRATANTE</b>.
  </p>

  <div class="clausula-title">CLÁUSULA SEGUNDA – DAS OBRIGAÇÕES DA <b>CONTRATADA</b></div>
  <p>
   2.1 Prestar os Serviços nos prazos e segundo as condições convencionadas neste Contrato e no Plano de Serviços,
   empregando pessoas de boa qualificação profissional e responsabilizando-se integralmente pelos Serviços prestados.
  </p>
  <p>
   2.2 Prestar à <b>CONTRATANTE</b> todas as informações necessárias para o bom atendimento dos Serviços prestados.
  </p>
  <p>
   2.3 Disponibilizar à <b>CONTRATANTE</b> acesso a Plataforma, de forma a permitir que a
   <b>CONTRATANTE</b> acompanhe e fiscalize a execução dos Serviços contratados.
  </p>
  <p>
   2.4 Viabilizar ou realizar, conforme o caso, os exames médicos clínicos ocupacionais (exames admissionais,
   periódicos, demissionais, retorno ao trabalho) conforme indicação do protocolo do PCMSO – Programa de Controle
   Médico de Saúde Ocupacional (os “Exames Ocupacionais”). Os exames admissionais serão realizados quando da efetiva
   contratação do empregado, ou seja, após a finalização do processo seletivo da <b>CONTRATANTE</b>.
  </p>
  <p>
   2.5 Caberá a <b>CONTRATADA</b> a guarda dos documentos (prontuários médicos) referente aos Exames Ocupacionais
   viabilizados ou executados por esta, ou seja, somente dos documentos elaborados após a assinatura do presente <b>Contrato</b>
  </p>
  <p>
   2.6 Na máxima extensão permitida pela legislação aplicável, nos casos de prejuízo sobre os quais a
   <b>CONTRATADA</b> tenha comprovada responsabilidade, o valor máximo que a <b>CONTRATADA</b> se responsabilizará
   será o montante total pago pela <b>CONTRATANTE</b> na respectiva contratação.
  </p>
  <div class="clausula-title">CLÁUSULA TERCEIRA - DAS OBRIGAÇÕES DA <b>CONTRATANTE</b></div>
  <p>
   3.1 Designar a data e a hora em que serão prestados os Serviços, bem como preposto responsável pelo acompanhamento
   do profissional técnico da <b>CONTRATADA</b>
  </p>
  <p>
   3.2 Atualizar através de layout fornecido pela <b>CONTRATADA</b> os dados dos seus funcionários para realização
   dos Exames Ocupacionais.
  </p>
  <p>
   3.2.1 A atualização deverá ser feita quando ocorrer mudança de endereço, substituição de gestores, alteração de
   telefones ou e-mails, surgimento de novos setores, modificação das funções de seus funcionários, e demais dados
   que possam vir a colaborar e/ou interferir no resultado das avaliações de saúde e emissão de documentos do
   programa e necessária para o gerenciamento dos Serviços a serem prestados.
  </p>
  <p>
   3.2.2 A <b>CONTRATANTE</b> se responsabiliza pela veracidade das informações dos dados fornecidos, sendo que
   falhas advindas destes, eximem qualquer responsabilidade da <b>CONTRATADA</b>, seja de cunho material ou moral.
  </p>
  <p>
   3.2.3 A <b>CONTRATANTE</b> se obriga a informar à <b>CONTRATADA</b> mensalmente, a quantidade atualizada de
   funcionários (candidatos+atual+admitidos+demitidos no mês), para que seja emitida a fatura, caso opte pelo plano
   mensal.
  </p>
  <p>
   3.3 Efetuar o pagamento da nota fiscal/fatura conjunta através de depósito bancário a <b>CONTRATADA</b>, conforme
   o prazo e as condições estabelecidas no Plano de Serviços contratado.
  </p>
  <p>
   3.4 Acatar as orientações técnicas da <b>CONTRATADA</b>, pertinentes à legislação de Medicina do Trabalho, bem
   como das previstas neste Contrato.
  </p>
  <p>
   3.4.1 A responsabilidade pelo não cumprimento das orientações previstas neste item, será assumida integralmente
   pela <b>CONTRATANTE</b>, perante os órgãos de fiscalização, caso esta venha a ocorrer.
  </p>
  <p>
   3.5 Informar à <b>CONTRATADA</b> sobre as prioridades na realização dos Serviços, para que, a partir delas, seja
   elaborado um cronograma de Serviços, a ser aprovado pelas partes, que fará parte integrante deste e norteará toda
   a execução do presente Contrato
  </p>
  <p>
   3.6 Agendar os exames ocupacionais nos ambulatórios próprios da <b>CONTRATADA</b> ou na rede credenciada, conforme
   o caso, por intermédio da Plataforma.
  </p>
  <p>
   3.7 Cuidar para que os seus funcionários compareçam no dia e no horário agendado. Em caso de impossibilidade no
   comparecimento, desmarcar a consulta agendada do funcionário, com no mínimo de 24 (vinte e quatro) horas de
   antecedência.
  </p>
  <p>
   3.8 Sendo alocado recurso para realização dos exames periódicos nas dependências da unidade, a
   <b>CONTRATANTE</b> garantirá a <b>CONTRATADA</b> a adesão de 70% (setenta por cento) dos exames agendados.
  </p>
  <p>
   3.9 A <b>CONTRATANTE</b> possui ciência de que foi informada e orientada pela <b>CONTRATADA</b> da obrigatoriedade
   da implementação de todas as Normas Regulamentadoras (NR) da Portaria nº 3.214/78 do Ministério do Trabalho e
   Emprego, no que couber, possuindo ciência, também, que os Serviços contratados, referem-se única e tão somente
   àqueles especificados na Cláusula Primeira deste Contrato.
  </p>
  <div class="clausula-title">CLÁUSULA QUARTA – DA REMUNERAÇÃO</div>
  <p>
   4.1 Pelos Serviços contratados, a <b>CONTRATANTE</b> pagará à <b>CONTRATADA</b> a remuneração prevista no Plano de
   Serviços escolhido pela <b>CONTRATANTE</b>
  </p>
  <p>
   4.2 Na remuneração pactuada estão inclusos todos os tributos previstos em lei, bem como os custos de qualquer
   natureza decorrentes da prestação dos Serviços, que correrão, exclusivamente, por conta da <b>CONTRATADA</b>.
  </p>
  <p>
   4.2 Na remuneração pactuada estão inclusos todos os tributos previstos em lei, bem como os custos de qualquer
   natureza decorrentes da prestação dos Serviços, que correrão, exclusivamente, por conta da <b>CONTRATADA</b>.
  </p>
  <p>
   4.4 A remuneração pactuada será reajustada anualmente, após 12 (doze) meses de vigência do Contrato, utilizando-se
   a variação apurada no período pelo IPCA ou pelo índice que vier a substitui-lo. Caso ocorram modificações na
   legislação que alterem a periodicidade mínima de reajuste dos contratos, esta cláusula passará por processo de
   reavaliação, sempre com a concordância de ambas as partes.
  </p>
  <div class="clausula-title">CLÁUSULA QUINTA – DA VIGÊNCIA E DA RESCISÃO</div>
  <p>
   5.1 O presente Contrato entrará em vigor na data de sua assinatura e terá validade de 24 (vinte e quatro) meses,
   sendo renovado automaticamente por iguais e sucessivos períodos, salvo notificação em sentido contrário enviado
   por qualquer das Partes com no mínimo 30 (trinta) dias de antecedência ao término do respetivo período em vigor
  </p>
  <p>
   5.2 É permitido a qualquer das partes rescindir o presente Contrato a qualquer tempo e sem incidência de ônus de
   qualquer espécie, com notificação prévia de 30 (trinta) dias de antecedência, excetuando-se os débitos pendentes
   anteriores a data da rescisão.
  </p>
  <p>
   5.3 O presente Contrato poderá, ainda, ser rescindido por qualquer das Partes, imediatamente, nos seguintes
   casos:<br />
  </p>

  <p>
   a) Em caso de inadimplência, total ou parcial, de qualquer das disposições deste Contrato que não seja sanada no
   prazoFFFF de 10 (dez) dias, a contar da data de recebimento de notificação escrita referente ao inadimplemento,
   enviada pela Parte adimplente;
  </p>
  <p>
   b) Em caso de dissolução, liquidação, recuperação judicial ou extrajudicial, falência ou insolvência de qualquer
   das Partes;
  </p>
  <p>
   c) Quando as atividades normais de qualquer das Partes forem sustadas, proibidas ou substancialmente alternadas em
   consequência de medidas diretas tomadas por quaisquer autoridades governamentais ou órgãos do Poder Público; e/ou;
  </p>
  <p>
   d) Houver a cessão total ou parcial dos direitos e obrigações decorrentes deste Contrato a terceiros, sem a prévia
   anuência por escrito da outra Parte;
  </p>

  <p>
   5.4 Em caso de rescisão por culpa da <b>CONTRATADA</b>, esta deverá encontrar um novo prestador de serviços e
   ajudar na transição, para que a <b>CONTRATANTE</b> não sofra nenhum dano.
  </p>
  <div class="clausula-title">CLÁUSULA SEXTA – DO SIGILO E DA CONFIDENCIALIDADE</div>
  <p>
   6.1 As Partes reconhecem que certas informações a que tiverem acesso em virtude do presente Contrato são
   confidenciais e constituem segredos comerciais valiosos (as “Informações Confidenciais”). As Informações
   Confidenciais serão compartilhadas entre as Partes em forma oral, escrita ou outra forma tangível,
   independentemente de marcação específica, conforme estabelece o presente Contrato.
  </p>
  <p>
   6.2 Para resguardar o sigilo e a confidencialidade das Informações Confidenciais, as Partes deverão envidar todos
   os esforços possíveis e empregar os mesmos meios que utilizam para proteção de suas próprias informações
   confidenciais.
  </p>
  <p>
   6.3 As Partes concordam que as restrições desta seção estarão em vigor durante a vigência deste Contrato e assim
   permanecerão após o encerramento ou vencimento deste Contrato.
  </p>
  <p>
   6.4 Caso, em virtude de disposição legal e/ou judicial, uma das Partes tiver que revelar as Informações
   Confidenciais recebidas nos termos do presente Contrato, deverá notificar imediatamente por escrito a outra Parte
   e colaborar, na medida do possível, no sentido de obter juntamente, se for o caso, uma medida que garanta que
   qualquer parte das Informações Confidenciais a serem reveladas seja usada tão somente para os fins para os quais
   tal revelação se faça obrigatória por força da disposição legal e/ou judicial aplicável.
  </p>
  <div class="clausula-title">CLÁUSULA SÉTIMA – DA AUTONOMIA DAS PARTES</div>
  <p>
   7.1 A relação estabelecida entre as Partes não configura, de forma alguma, vínculo empregatício, representação
   comercial, ou qualquer outra relação jurídica além da fixada neste Contrato, tampouco representa quaisquer outros
   direitos para as Partes, além dos serviços supramencionados, devendo ser interpretado restritivamente, de modo a
   não permitir qualquer interpretação diferente da objetivada pelas Partes.
  </p>
  <p>
   7.2 Cada Parte se obriga a observar e respeitar todas as determinações relativas à legislação trabalhista, de
   previdência e seguridade social, bem como a arcar com todos e quaisquer encargos de natureza trabalhista e/ou
   previdenciária devidos aos seus respectivos sócios, empregados, contratados, subcontratados, diretores,
   colaboradores, prepostos ou terceiros, os quais são e continuarão sendo de sua exclusiva responsabilidade, não
   respondendo a outra Parte por qualquer de tais obrigações, ainda que em caráter solidário ou subsidiário.
  </p>
  <p>
   7.3 Cada Parte assume, isoladamente, todos os ônus e responsabilidades decorrentes de ações, reclamações ou
   reivindicações de natureza trabalhista, previdenciária ou securitária, eventualmente movidas por seus respectivos
   sócios, empregados, contratados, subcontratados, diretores, colaboradores, prepostos ou terceiros contra a outra
   Parte, obrigando-se a arcar isoladamente com qualquer pagamento, indenização e demais ônus daí advindos, bem como
   a requerer a exclusão da lide e a reembolsar à outra Parte qualquer valor, despesa e/ou taxa incorrido para se
   defender em tais demandas, especialmente, mas não exclusivamente, custas judiciais, despesas processuais e
   honorários advocatícios.
  </p>
  <p>
   7.4 A <b>CONTRATADA</b> declara que mantém com veracidade, precisão e de forma completa os livros e registros
   relacionados, direta ou indiretamente, com sua atividade empresarial, incluindo, mas não se limitando, às
   atividades relacionadas ao escopo do presente Contrato, bem como todas as licenças necessárias.
  </p>
  <div class="clausula-title">CLÁUSULA OITAVA – DA ANTICORRUPÇÃO</div>
  <p>
   8.1 As Partes declaram e garantem que têm conhecimento e compreendem as disposições das leis aplicáveis ao combate
   à corrupção, suborno, terrorismo e lavagem de dinheiro em vigor, dentre elas a Lei Anticorrupção Brasileira (Lei
   12.846/2013) e a Lei de Lavagem de Dinheiro (Lei 9.613/1998) (coletivamente “Leis Anticorrupção”). Ao assinar o
   presente Contrato, cada uma das Partes declara que cumpriu e que continuará cumprindo, durante o prazo deste
   Contrato, todas as Leis Anticorrupção.
  </p>
  <p>
   8.2 As Partes obrigam-se a manter os mais altos padrões de honestidade, integridade, ética e legalidade em todos
   os seus atos e na prestação de seus serviços, motivo pelo qual se comprometem e concordam que nenhuma das Partes,
   nem seus sócios, conselheiro, diretores, agentes, empregados, subcontratados ou outras pessoas associadas com ou
   agindo em nome de cada uma das Partes fez, fará, deu ou dará quaisquer pagamentos, direta ou indiretamente, a
   qualquer oficial do governo na jurisdição na qual ambas as Partes conduzem seus negócios, relativos a qualquer
   oportunidade, contrato, licença, autorização, certificado, solicitação, aprovação, registro, renúncia ou outra
   autorização relacionada ao negócio de ambas as Partes, exceto se estes pagamentos forem legais.
  </p>
  <div class="clausula-title">CLÁUSULA NONA – DA PROTEÇÃO DE DADOS</div>
  <p>
   9.1 Cada Parte se obriga e concorda que o tratamento de dados e informações capazes de identificar pessoas físicas
   de base de dados da outra Parte, bem como o conteúdo ou as comunicações privadas ocorridas durante a prestação dos
   serviços (os “Dados Pessoais”) deverá ser realizado de acordo com o que determina a legislação brasileira sobre
   privacidade e proteção de dados pessoais.
  </p>
  <p>
   9.2 O tratamento dos Dados Pessoais fornecidos pelas Partes está limitado às finalidades previstas neste Contrato
   e as exigências legais e regulatórias que assim especificamente determinem. É vedado o tratamento dos Dados
   Pessoais para finalidades diferentes das expressamente determinadas neste Contrato, o que inclui coletar,
   armazenar, divulgar e/ou fornecer a terceiros quaisquer das informações obtidas por meio deste Contrato para
   finalidades não expressamente indicadas neste Contrato.
  </p>
  <p>
   9.3 As Partes deverão implementar medidas razoáveis e apropriadas para proteger os Dados Pessoais disponibilizados
   pela outra Parte para fins de execução deste Contrato, incluindo medidas contra perda, acesso ou revelação
   acidental ou ilegal, garantindo ainda backup e plano de recuperação de dados em caso de incidente com as
   informações veiculadas e armazenadas.
  </p>
  <p>
   9.4 Os Dados Pessoais compartilhados serão armazenados durante a vigência deste Contrato e, após o seu término,
   por um período não superior aos prazos definidos na legislação aplicável, devendo, ao final, serem devolvidos ou
   descartados, conforme acordado entre as Partes oportunamente.
  </p>
  <div class="clausula-title">CLÁUSULA DÉCIMA – DAS DISPOSIÇÕES GERAIS</div>
  <p>
   10.1 As Partes reconhecem, expressamente, que o presente Contrato é o único instrumento a regular a relação entre
   ambas quanto ao seu objeto, razão pela qual dão por rescindido de pleno direito todos e quaisquer contratos,
   compromissos e outros acordos, tácitos ou expressos, que porventura tenham mantida anteriormente com relação ao
   objeto deste Contrato.
  </p>
  <p>
   10.2 A <b>CONTRATADA</b> apenas poderá utilizar as marcas e denominações sociais da <b>CONTRATANTE</b>, para
   qualquer finalidade que seja, se houver autorização expressa por parte da <b>CONTRATANTE</b>, ficando
   expressamente vedada a sua inclusão em atividades de publicidade e propaganda a terceiros, ou a qualquer outro
   título.
  </p>
  <p>
   10.3 A <b>CONTRATANTE</b>, neste ato, declara ter ciência de que a <b>CONTRATADA</b> é titular dos direitos de
   propriedade intelectual relativos à Plataforma. A <b>CONTRATADA</b> não poderá utilizar, copiar, modificar,
   exibir, apagar, distribuir, realizar download, arquivar, transmitir, publicar, vender, revender, adaptar, realizar
   engenharia reversa ou criar obras derivadas da Plataforma, sem o consentimento prévio e por escrito da
   <b>CONTRATADA</b>.
  </p>
  <p>
   10.4 O presente Contrato não presume nem confere exclusividade a qualquer uma das Partes, podendo, tanto a
   <b>CONTRATANTE</b> quanto a <b>CONTRATADA</b>, contratar outras pessoas físicas ou jurídicas para o mesmo objeto
   estabelecido no Contrato
  </p>
  <p>
   10.5 Este Contrato obriga as Partes e seus respectivos sucessores e herdeiros a qualquer título. A
   <b>CONTRATADA</b>
   poderá atualizar os termos deste Contrato a qualquer tempo, conforme entenda necessário e independentemente de
   qualquer alteração ou aditamento contratual
  </p>
  <p>
   10.6 A eventual tolerância de qualquer das Partes a infrações ou o descumprimento das condições estipuladas no
   presente Contrato, cometidas pela outra Parte, será tida como ato de mera liberalidade não constituindo em
   precedente, novação ou renúncia a direitos que a legislação ou o Contrato assegurem às Partes
  </p>
  <p>
   10.7 Se qualquer cláusula ou dispositivo deste Contrato for considerado nulo ou sem efeito, no todo ou em parte,
   as demais deverão permanecer válidas e serão interpretadas de forma a preservar a sua validade.
  </p>
  <p>
   10.8 As notificações a serem enviadas a qualquer das Partes em razão do pactuado neste Contrato deverão ser
   formalizadas por escrito e enviadas, por e-mail ou mediante entrega pessoal com protocolo, para os endereços
   constantes do preâmbulo do presente Contrato.
  </p>
  <p>
   10.9 Os direitos e as obrigações decorrentes do presente Contrato não poderão ser cedidos por uma das Partes, sem
   o prévio e expresso consentimento da outra Parte.
  </p>
  <p>
   10.10 Nenhuma das Partes será responsável, perante a outra, por quaisquer falhas nas obrigações constantes neste
   Contrato causadas, total ou parcialmente, por eventos de caso fortuito ou de força maior, assim considerados
   aqueles definidos na legislação vigente.
  </p>
  <p>
   10.11 Os Serviços disponibilizados pela <b>CONTRATADA</b> somente poderão ser adquiridos por pessoas jurídicas
   devidamente constituídas. Ao aceitar este Contrato, a <b>CONTRATANTE</b> declara estar devidamente representada
   nos termos da legislação vigente.
  </p>
  <p>
   10.12 A contratação dos Serviços somente se concretizará e vinculará as Partes após o aceite deste Contrato por
   parte da <b>CONTRATANTE</b>.
  </p>
  <div class="clausula-title">CLÁUSULA DÉCIMA PRIMEIRA – DA LEGISLAÇÃO E DO FORO DE ELEIÇÃO</div>
  <p>
   11.1 Este Contrato será regido pelas leis em vigor na República Federativa do Brasil. Fica eleito o Foro da
   Comarca de São Paulo, Estado de São Paulo, para dirimir quaisquer dúvidas ou controvérsias decorrentes do presente
   Contrato, com exclusão de todos os outros, por mais privilegiados que sejam ou possam vir a ser.
  </p>
  <p>
   E por assim estarem, justas e contratadas, concordam com o presente Contrato, para que este produza todos os seus
   efeitos legais.
  </p>
 </div>
</template>

<script>
export default {
 name: 'ContratoNovoNegocio',
}
</script>

<style scoped>
p {
 font-size: 0.8rem;
 margin-bottom: 5px;
 margin-top: 5px;
}

.title,
.clausula-title {
 font-weight: 700;
 font-size: 1rem !important;
 margin-bottom: 10px;
 margin-top: 10px;
}

.title {
 text-align: center;
 margin-bottom: 20px;
}
</style>
