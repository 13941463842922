<template>
  <div class="step-container">
    <div class="d-flex align-center justify-center mb-2">
      <div class="back-button" v-if="this.actualStep < 7" @click="$router.back()">
        <v-icon size="25" color="#fff">mdi-chevron-left</v-icon>
      </div>
      <div class="text-center mt-3">
        <img width="60px" src="@/assets/logo.png" alt="Logo limer" />
      </div>
    </div>
    <div class="d-flex align-center justify-center mb-3">
      <span :key="actualStep" class="ml-1">
        {{ actualStep }} de {{ steps.length }} passos - {{ getStepByNumber(actualStep).title }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CadastroStepCard from '@/modules/Cadastro/steps/CadastroStepCard'
import { steps } from '@/utils/constants'

export default {
  components: {
    CadastroStepCard,
  },
  data: () => ({
    steps,
  }),
  computed: {
    offsetPixel() {
      return `-${(this.actualStep - 1) * 120}px`
    },
    ...mapState('cadastro', ['actualStep']),
  },
  methods: {
    getStepByNumber(step) {
      return this.steps.find(item => item.number === step)
    },
  },
}
</script>

<style lang="scss" scoped>
.step-container {
  position: static;
  display: flex;
  flex-direction: column;
  background: #006bf2;
  color: rgb(230, 230, 230) !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.back-button {
  position: absolute;
  left: 0;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  cursor: pointer;
}

.back-button:hover {
  transform: scale(1.1);
}</style>
