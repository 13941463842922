const STATUS_EMPRESA = {
  TODAS: -1,
  ATIVA: 1,
  INATIVA: 2,
  BLOQUEADA: 3,
}

const TIPOS_EXAME = [
  { value: 1, text: 'Admissional', slug: 'admissional' },
  { value: 2, text: 'Demissional', slug: 'demissional' },
  { value: 3, text: 'Periódico', slug: 'periodico' },
  { value: 4, text: 'Mudança de Função', slug: 'mudanca-de-funcao' },
  { value: 5, text: 'Retorno ao Trabalho', slug: 'retorno-ao-trabalho' },
]

const SEXOS = [
  { value: 'M', text: 'Masculino' },
  { value: 'F', text: 'Feminino' },
]

const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)(19[0-8][0-9]|199[0-9]|20[0-9]{2}|2100)/

const COMMON_STATUS = {
  ATIVA: 1,
  INATIVA: 2,
}

const steps = [
  {
    title: 'Crie seu perfil',
    body: 'Crie um login e senha para sua conta',
    icon: 'mdi-account-outline',
    number: 1,
  },
  {
    title: 'Crie uma organização',
    body: 'Qual o nome que você quer dar para seu grupo de empresas',
    icon: 'mdi-account-outline',
    number: 2,
  },
  {
    title: 'Adicionar uma empresa',
    body: 'Adicione sua primeira empresa a sua organização',
    icon: 'mdi-label-percent-outline',
    number: 3,
  },
  {
    title: 'Dados da sua empresa',
    body: 'Escolha o segmento que sua empresa está enquadrada',
    icon: 'mdi-label-percent-outline',
    number: 4,
  },
  {
    title: 'Faturamento',
    body: 'Dados para emissão das faturas futuras',
    icon: 'mdi-label-percent-outline',
    number: 5,
  },
  {
    title: 'Termo de aceite',
    body: 'Para sua segurança e segurança da informação leia nosso contrato ao lado',
    icon: 'mdi-file-document-edit-outline',
    number: 6,
  },
  {
    title: 'Sucesso!',
    body: 'Sua conta foi criada, agora você já pode utilizar nossa plataforma',
    icon: 'mdi-trophy-outline',
    number: 7,
  },
]
const estados = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

export { dateRegex, SEXOS, STATUS_EMPRESA, TIPOS_EXAME, COMMON_STATUS, estados, steps }
