<template>
  <v-container class="fill-height" style="max-width: 800px">
    <v-row no-gutters align="center" justify="center" class="fill-height px-5">
      <v-col cols="12">
        <v-row no-gutters justify="center">
          <v-col cols="12">
            <v-row no-gutters align="center" justify="center" class="mb-5">
              <v-col cols="12" class="head-title mb-2 text-center">Vamos dar um nome para sua organização</v-col>
              <v-col cols="12" class="subtitle text-center"
                >Dê um nome para sua organização, assim você poderá adcionar várias empresas a essa organização</v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <v-form ref="form">
          <div class="d-flex flex-column justify-center">
            <div class="font-weight-bold mt-3 pl-3 mb-2">Como devemos chamar sua organização?</div>
            <v-text-field
              placeholder="Dê um nome para sua organização"
              outlined
              v-model="organizacao.nome"
              :rules="[
                () => $v.organizacao.nome.required || 'Campo obrigatório',
                () => $v.organizacao.nome.minLength || 'Nome deve ter pelo menos 3 caracteres',
              ]"
            ></v-text-field>
          </div>
        </v-form>
        <v-row no-gutters justify="center">
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded x-large class="elevation-12" @click="nextStep()" :loading="loading">
            <span class="capitalize">Seguir</span>
            <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import cadastroMixin from './cadastroMixin'

export default {
  mixins: [validationMixin, cadastroMixin],
  validations: {
    organizacao: {
      nome: {
        required,
        minLength: minLength(3),
      },
    },
  },
  data() {
    return {
      organizacao: {
        nome: null,
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters('cadastro', ['getOrganizacao']),
  },
  methods: {
    ...mapMutations('cadastro', ['setOrganizacao']),

    async nextStep() {
      if (!this.$refs.form.validate()) return

      this.loading = true
      try {
        this.setOrganizacao(this.organizacao)
        this.actualStepWrapper = 3
        this.$router.push({ name: 'cadastro-empresa' })
      } catch (error) {
        this.showErrorOnSnackbar(error.response.data.message)
      } finally {
        this.loading = false
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 2
    this.organizacao = { ...this.getOrganizacao }
  },
}
</script>
