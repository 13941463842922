import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#006bf2',
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
})
