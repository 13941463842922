<template>
  <div>
    <div class="mb-6" v-for="plano in planos" :key="plano.slug">
      <PlanoItem
        :plano="plano"
        :isSelected="planoSelecionado && planoSelecionado.slug === plano.slug"
        @update-selected-plano="updateSelectedPlano"
      />

      <v-expand-transition mode="out-in">
        <BeneficiosPlano
          class="ma-auto mt-2"
          :plano="planoSelecionado"
          @go-to-cadastro="$emit('go-to-cadastro')"
          v-if="$vuetify.breakpoint.smAndDown && planoSelecionado.slug === plano.slug"
        />
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import PlanoItem from './PlanoItem'
import BeneficiosPlano from './BeneficiosPlano'

export default {
  name: 'PlanosList',
  props: {
    planos: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      planoSelecionado: null,
    }
  },
  components: { PlanoItem, BeneficiosPlano },
  methods: {
    updateSelectedPlano(planoSelecionado) {
      this.planoSelecionado = planoSelecionado
      this.$emit('update-selected-plano', planoSelecionado)
    },
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.updateSelectedPlano(this.planos[0])
  },
}
</script>

<style></style>
