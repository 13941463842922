<template>
  <div class="slider-colaboradores">
    <div class="d-flex flex-column text-center justify-center align-center">
      <div class="label-colaboradores">Quantidade de colaboradores em sua empresa</div>
      <v-sheet width="60vw">
        <v-slider color="#006bf2" thumb-size="50px" track-color="#F2F2F2" v-model="qtdeColaboradores" min="1"
          max="5000"></v-slider>
      </v-sheet>
      <div class="colaboradores">
        <v-text-field v-model="qtdeColaboradores" class="mt-0 pt-0 mr-2" v-mask="'####'" color="#071aff"
          style="width: 60px"></v-text-field>Colaboradores
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderColaboradores',
  props: {
    value: {
      required: true
    }
  },
  computed: {
    qtdeColaboradores: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    }
  },
}
</script>

<style scoped>
.slider-colaboradores>>>.v-slider--horizontal .v-slider__track-container {
  height: 12px !important;
}

.slider-colaboradores>>>.v-slider__thumb {
  height: 40px !important;
  width: 40px !important;
  background-image: url('../../assets/chevron-right.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
}

.slider-colaboradores>>>.v-slider__thumb::after {
  border: 5px solid;
  border-radius: 50%;
  border-color: #fff;
}

.slider-colaboradores>>>.v-slider__thumb::before {
  height: 0px !important;
  width: 0px !important;
}

.slider-colaboradores>>>.v-slider__track-fill {
  border-radius: 50px;
}

.slider-colaboradores>>>.v-slider__track-background {
  border-radius: 50px;
}

.planos>>>.theme--light.v-input input {
  color: #006bf2;
  text-align: center;
  font-size: 1.5rem;
}

.label-colaboradores {
  color: #727491;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

.colaboradores {
  font-size: 1.5rem;
  display: flex;
  margin-top: 2vh;
}
</style>
