<template>
  <v-container class="cadastro-empresa" style="max-width: 1000px">
    <v-row align="center" justify="center" class="head">
      <v-col cols="12">
        <div class="head-title mb-2 text-center">Adicione sua primeira empresa</div>
        <div cols="12" class="subtitle text-center">
          Adicione a sua primeira empresa, digite o CNPJ da unidade que deseja gerenciar
        </div>
      </v-col>
    </v-row>
    <v-form ref="cpfCnpjForm" @submit.prevent>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <div class="input-title">Qual o tipo da sua empresa?</div>
          <v-radio-group v-model="empresa.tipo_empresa" row @change="onChangeTipoEmpresa">
            <v-radio label="Pessoa jurídica" value="pessoa_juridica"></v-radio>
            <v-radio label="Pessoa física" value="pessoa_fisica"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" v-if="empresa.tipo_empresa === 'pessoa_juridica'">
          <div class="input-title">Qual o CNPJ da sua empresa?</div>
          <v-text-field
            placeholder="Digite o CNPJ da sua empresa"
            :loading="loadingCpfCnpj"
            outlined
            v-mask="'##.###.###/####-##'"
            v-model="empresa.cnpj"
            :rules="[
              () => $v.empresa.cnpj.required || 'Campo obrigatório',
              () => $v.empresa.cnpj.validateCnpj || 'CNPJ inválido',
            ]"
            @click:append="buscaEmpresa(empresa.cnpj)"
            @change="buscaEmpresa"
            append-icon="mdi-magnify"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" v-if="empresa.tipo_empresa === 'pessoa_fisica'">
          <div class="input-title">Qual o CPF da sua empresa?</div>
          <v-text-field
            :loading="loadingCpfCnpj"
            placeholder="Digite o CPF da sua empresa"
            outlined
            v-mask="'###.###.###-##'"
            v-model="empresa.cpf"
            :rules="[() => $v.empresa.cpf.required || 'Campo obrigatório', v => validaCpf(v) || 'Campo inválido']"
            @click:append="buscaEmpresa(empresa.cpf)"
            append-icon="mdi-magnify"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-col cols="12">
      <v-alert outlined color="error" text :value="cpfCnpjInvalido"> CPF/CNPJ inválido </v-alert>
    </v-col>
    <v-expand-transition>
      <v-form ref="form" v-if="showForm" @submit.prevent>
        <v-row>
          <v-col cols="12">
            <div class="input-title">Qual a Razão Social da sua empresa</div>
            <v-text-field
              placeholder="Digite a Razão Social da sua empresa"
              outlined
              v-model="empresa.razao_social"
              :rules="[() => $v.empresa.razao_social.required || 'Campo obrigatório']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="input-title">Código CNAE principal da sua empresa</div>
            <v-text-field
              placeholder="Digite o código CNAE principal da sua empresa"
              outlined
              v-model="empresa.cnae_principal_codigo"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="input-title">Qual o CEP da sua empresa</div>
            <v-text-field
              placeholder="Digite o CEP da sua empresa"
              outlined
              v-mask="'##.###-###'"
              v-model="empresa.endereco_cep"
              :rules="[() => $v.empresa.endereco_cep.required || 'Campo obrigatório']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="input-title">Qual a UF da sua empresa</div>
            <v-select
              placeholder="Digite a UF da sua empresa"
              outlined
              :items="estados"
              v-model="empresa.endereco_uf"
              @change="buscaCidades"
              :rules="[() => $v.empresa.endereco_uf.required || 'Campo obrigatório']"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="input-title">Qual a cidade da sua empresa</div>
            <v-autocomplete
              placeholder="Digite a cidade da sua empresa"
              outlined
              :items="cidades"
              :loading="loadingCidades"
              item-text="nome"
              item-value="id_cidade"
              :disabled="cidades.length === 0"
              v-model="empresa.endereco_id_cidade"
              cache-items
              :rules="[() => $v.empresa.endereco_id_cidade.required || 'Campo obrigatório']"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <div class="input-title">Qual a endereço da sua empresa</div>
            <v-text-field
              placeholder="Digite o endereço da sua empresa"
              outlined
              v-model="empresa.endereco_rua"
              :rules="[() => $v.empresa.endereco_rua.required || 'Campo obrigatório']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="input-title">Qual o bairro da sua empresa</div>
            <v-text-field
              placeholder="Digite o bairo da sua empresa"
              outlined
              v-model="empresa.endereco_bairro"
              :rules="[() => $v.empresa.endereco_bairro.required || 'Campo obrigatório']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="input-title">Número do endereço da sua empresa</div>
            <v-text-field
              placeholder="Digite o número do endereço da sua empresa"
              outlined
              v-model="empresa.endereco_numero"
              :rules="[
                () => $v.empresa.endereco_numero.required || 'Campo obrigatório',
                () => $v.empresa.endereco_numero.integer || 'Campo inválido',
              ]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="primary" @click="nextStep" :disabled="cpfCnpjInvalido || loading" rounded x-large>
              <span class="capitalize">Seguir</span>
              <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-expand-transition>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { estados } from '@/utils/constants'
import { required, integer } from 'vuelidate/lib/validators'
import cadastroService from '@/services/cadastro'
import validaCnpj from '@/utils/validaCnpj'
import validaCpf from '@/utils/validaCpf'
import cadastroMixin from './cadastroMixin'

export default {
  mixins: [validationMixin, cadastroMixin],
  validations: {
    empresa: {
      cnpj: {
        required,
        validateCnpj(cnpj) {
          return validaCnpj(cnpj)
        },
      },
      cpf: {
        required,
      },
      razao_social: {
        required,
      },
      cnae_principal_codigo: {
        required,
      },
      endereco_cep: {
        required,
      },
      endereco_uf: {
        required,
      },
      endereco_id_cidade: {
        required,
      },
      endereco_rua: {
        required,
      },
      endereco_bairro: {
        required,
      },
      endereco_numero: {
        required,
        integer,
      },
    },
  },
  data() {
    return {
      empresa: {
        tipo_empresa: 'pessoa_juridica',
        cnpj: null,
        cpf: null,
        cnae_principal_codigo: null,
        endereco_bairro: null,
        endereco_cep: null,
        endereco_id_cidade: null,
        endereco_numero: null,
        endereco_rua: null,
        endereco_uf: null,
        razao_social: null,
      },
      loadingCpfCnpj: false,
      loading: false,
      estados,
      cidades: [],
      loadingCidades: false,
      cpfCnpjInvalido: false,
      showForm: false,
    }
  },
  computed: {
    ...mapGetters('cadastro', ['getEmpresa']),
  },
  methods: {
    ...mapMutations('cadastro', ['setEmpresa']),
    onChangeTipoEmpresa() {
      this.cpfCnpjInvalido = false
      this.empresa.cpf = null
      this.empresa.cnpj = null
      this.$refs.cpfCnpjForm.resetValidation()
      this.$refs.form.resetValidation()
      this.$refs.form.reset()
      this.showForm = false
    },
    validaCpf(cpf) {
      if (process.env.VUE_APP_ENV === 'DEV') return true
      return validaCpf(cpf)
    },
    async buscaEmpresa(cpfCnpj) {
      this.cpfCnpjInvalido = false
      if (validaCnpj(cpfCnpj) || this.validaCpf(cpfCnpj)) {
        this.loadingCpfCnpj = true
        try {
          console.log()
          const validarCnpj = this.empresa.tipo_empresa === 'pessoa_juridica'
          const { data } = await cadastroService.verificaEmpresa(cpfCnpj, validarCnpj)
          this.empresa = {
            ...this.empresa,
            ...data,
          }
          this.showForm = true
          if (this.empresa.endereco_id_cidade) {
            this.buscaCidades()
          }
        } catch ({ response }) {
          this.cpfCnpjInvalido = true
          this.showErrorOnSnackbar(response.data.message)
        } finally {
          this.loadingCpfCnpj = false
        }
      }
    },
    validaEmpresaForm() {
      if (this.$refs.cadastroEmpresaForm.$refs.form.validate()) {
        this.nextStep()
      }
    },
    async nextStep() {
      if (this.$refs.cpfCnpjForm.validate() && this.$refs.form.validate()) {
        this.setEmpresa(this.empresa)
        this.actualStepWrapper = 4
        this.$router.push({ name: 'cadastro-segmentos' })
      }
    },
    async buscaCidades() {
      this.loadingCidades = true
      try {
        const { data } = await cadastroService.buscaCidades(this.empresa.endereco_uf)
        this.cidades = data
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      } finally {
        this.loadingCidades = false
      }
    },
    onChangeSwitch(value) {
      if (!value) {
        this.empresa.pcmso = null
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 3
  },
  mounted() {
    this.empresa = { ...this.getEmpresa }
    if (this.empresa.endereco_id_cidade) {
      this.buscaCidades()
    }
  },
}
</script>
<style scoped></style>
