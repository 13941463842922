<template>
  <div class="planos">
    <Header />
    <v-container :class="{ 'mt-4': $vuetify.breakpoint.mdAndUp }">
      <v-row>
        <v-col cols="12" class="plano-title">
          O plano ideal para sua <span class="highlight"> empresa</span>
        </v-col>
        <v-col cols="12">
          <SliderColaboradores v-model="qtdeColaboradores"/>
        </v-col>
        <v-col cols="12">
          <v-row v-if="loading || planos.length === 0" class="text-center text-lg-center justify-center align-center">
            <v-col cols="3" v-for="n in 3" :key="n">
              <v-skeleton-loader class="loader" type="image"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else class="justify-center align-center">
            <v-col lg="3" md="3" cols="12" v-for="(plano, index) in planos" :key="`${plano.tipo}-${index}`">
              <EmpresaPlanoCard :id="`plano-${plano.tipo}`" :plano="plano" @on-select-plan="onSelectPlan">
              </EmpresaPlanoCard>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from '@/modules/Plano/Header'
import { mapMutations, mapGetters } from 'vuex'
import SelecionarPlanos from '@/modules/Plano/SelecionarPlanos'
import EmpresaPlanoCard from '@/modules/Plano/EmpresaPlanoCard'
import cadastroService from '@/services/cadastro'
import SliderColaboradores from '@/modules/Plano/SliderColaboradores'

export default {
  name: 'Plano',
  components: { Header, EmpresaPlanoCard, SelecionarPlanos, SliderColaboradores },
  data() {
    return {
      qtdeColaboradores: 0,
      loading: false,
      planoSelected: null,
      planos: [],
      token: null
    }
  },
  computed: {
    ...mapGetters('cadastro', ['getEmpresa']),
  },
  methods: {
    ...mapMutations('cadastro', ['setEmpresa', 'setPlanoPadrao', 'setQtdeColaboradores']),
    updateColaboradores(value) {
      this.qtdeColaboradores = value
    },
    async getPlanos() {
      try {
        this.loading = true
        const { data } = await cadastroService.getPlanos(this.token)
        this.planos = structuredClone(data)
      } catch (error) {
        console.log(error)
        this.showErrorOnSnackbar(error.response.data.message)
      } finally {
        this.loading = false
      }
    },
    onSubmit() {
      this.$router.push({ path: `/cadastro/${this.token}/usuario` })
    },
    onSelectPlan(plano) {
      this.setPlanoPadrao(plano)
      this.setQtdeColaboradores(this.qtdeColaboradores)
      this.onSubmit()
    }
  },
  beforeMount() {
    if (this.$route?.params?.token) {
      this.token = encodeURIComponent(this.$route?.params?.token)
      this.getPlanos()
    }
    this.qtdeColaboradores = this.getEmpresa.qtde_colaboradores
  },
}
</script>

<style scoped>
.planos {
  margin-bottom: 1rem;
}

.plano-title {
  text-align: center;
  font-size: 3.5rem !important;
  margin-bottom: 1rem;
}

.highlight {
  color: #006bf2;
}

.loader>>>.v-skeleton-loader__image {
  height: 70vh !important;
}

.label-colaboradores {
  color: #727491;
  font-size: 1.2rem;
}

.colaboradores {
  font-size: 1.5rem;
  display: flex;
}
</style>
