import Vue from 'vue'
import VueTheMask, { mask } from 'vue-the-mask'
import { mapActions } from 'vuex'
import '@/core/filters'

Vue.directive(mask)

Vue.use(VueTheMask)
Vue.mixin({
  methods: {
    ...mapActions('layout', ['hideErrorOnSnackbar', 'showErrorOnSnackbar', 'hideAllMessages']),
  },
})
