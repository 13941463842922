<template>
  <v-app>
    <v-main>
      <SnackbarError v-model="statuses.errorOnSnackbar" :message="statuses.errorMessage" />
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import SnackbarError from '@/components/common/SnackbarError'

export default {
  name: 'App',
    components: {
    SnackbarError,
  },
  computed: {
    ...mapGetters('layout', ['statuses']),
  }
}
</script>
