var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"max-width":"1000px"}},[_c('v-row',{staticClass:"head",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"head-title mb-2 text-center"},[_vm._v("Faturamento")]),_c('div',{staticClass:"subtitle text-center",attrs:{"cols":"12"}},[_vm._v("recisamos de algumas informações sobre você")])])],1),_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-title"},[_vm._v("E-mail faturamento")]),_c('v-text-field',{attrs:{"placeholder":"Digite o e-mail de faturamento","outlined":"","rules":[
          () => _vm.$v.faturamento.email.required || 'Campo obrigatório',
          () => _vm.$v.faturamento.email.email || 'E-mail inválido',
        ]},model:{value:(_vm.faturamento.email),callback:function ($$v) {_vm.$set(_vm.faturamento, "email", $$v)},expression:"faturamento.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-title"},[_vm._v("Nome do responsável pelas faturas")]),_c('v-text-field',{attrs:{"placeholder":"Digite o nome do responsável","outlined":"","rules":[
          () => _vm.$v.faturamento.responsavel.required || 'Campo obrigatório',
          () => _vm.$v.faturamento.responsavel.minLength || 'Nome deve ter pelo menos 3 caracteres',
        ]},model:{value:(_vm.faturamento.responsavel),callback:function ($$v) {_vm.$set(_vm.faturamento, "responsavel", $$v)},expression:"faturamento.responsavel"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-title"},[_vm._v("Data para emissão da fatura")]),_c('v-select',{attrs:{"placeholder":"Escolha o melhor dia","outlined":"","items":_vm.faturamentoItems,"rules":[
          () => _vm.$v.faturamento.dia.required || 'Campo obrigatório',
          () => _vm.$v.faturamento.dia.numeric || 'Nome deve ter pelo menos 3 caracteres',
        ]},model:{value:(_vm.faturamento.dia),callback:function ($$v) {_vm.$set(_vm.faturamento, "dia", $$v)},expression:"faturamento.dia"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{staticClass:"elevation-12",attrs:{"color":"primary","rounded":"","x-large":""},on:{"click":function($event){return _vm.nextStep()}}},[_c('span',{staticClass:"capitalize"},[_vm._v("Seguir")]),_c('v-icon',{attrs:{"size":"20","right":""}},[_vm._v("mdi-chevron-right-circle-outline")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }