export const SET_ERROR_ON_SNACKBAR = 'SET_ERROR_ON_SNACKBAR'
export const HIDE_ALL_MESSAGES = 'HIDE_ALL_MESSAGES'

const state = {
  statuses: {
    errorOnSnackbar: false,
    successOnSnackbar: false,
    infoOnSnackbar: false,
    errorMessage: '',
  },
}

const mutations = {
  [SET_ERROR_ON_SNACKBAR]: (state, { value, errorMessage }) => {
    state.statuses.errorOnSnackbar = value
    state.statuses.errorMessage = errorMessage || 'Erro, entre em contato com o administrador'
  },
  [HIDE_ALL_MESSAGES]: state => {
    state.statuses.errorOnModal = false
    state.statuses.errorOnSnackbar = false
  },
}

const getters = {
  statuses: state => state.statuses,
}

const actions = {
  showErrorOnSnackbar({ commit }, errorMessage) {
    commit(HIDE_ALL_MESSAGES)
    if (typeof errorMessage === 'string') {
      commit(SET_ERROR_ON_SNACKBAR, { value: true, errorMessage })
    } else {
      commit(SET_ERROR_ON_SNACKBAR, { value: true, errorMessage: JSON.stringify(errorMessage) })
    }
  },
  hideErrorOnSnackbar({ commit }) {
    commit(SET_ERROR_ON_SNACKBAR, { value: false })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
