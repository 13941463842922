<template>
 <v-row class="contrato-assinaturas">
  <v-col cols="6">
   <div class="contratada">
    <div><b>CONTRATADA </b></div>
    <div class="assinatura limer">Limer Gestão de Sáude</div>
    <div>Limer Gestão de Sáude Corporativa</div>
    <div>CNPJ: 24.809.516/0001-40</div>
   </div>
  </v-col>
  <v-col cols="6">
   <div class="contratante">
    <div>
     <b class="text-uppercase">Representante</b>
    </div>
    <div class="assinatura"></div>
    <div>{{ assinante.nome }}</div>
    <div>{{ assinante.email }}</div>
   </div>
  </v-col>
 </v-row>
</template>
<script>
export default {
 name: 'ContratoAssinatura',
 props: {
  assinante: {
   required: true,
   type: Object,
  },
 },
}
</script>

<style scoped>
iframe {
 width: 50vw;
}

.contrato-assinaturas {
 margin-top: 50px;
}

.assinatura {
 font-family: 'Great Vibes', cursive !important;
 font-size: 1em;
 height: 70px;
 display: flex;
 justify-content: center;
 align-items: flex-end;
 width: 75%;
 margin-right: auto;
 margin-left: auto;
 margin-bottom: 10px;
 padding-bottom: 10px;
 margin-top: 10px;
 border-bottom: 1px solid #000;
 min-height: 1.5rem;
 line-height: 1.5rem;
}

.limer {
 color: #8db3e2;
}

.contratada {
 display: flex;
 flex-direction: column;
 text-align: center;
 margin-bottom: 5vh;
}

.contratante {
 display: flex;
 flex-direction: column;
 text-align: center;
 margin-bottom: 5vh;
}

.localization-info {
 text-decoration: underline;
 font-size: 0.8rem;
 cursor: pointer;
}

#assinatura-info {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding: 10px;
}

a {
 color: #454444;
}
</style>
