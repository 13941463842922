<template>
  <v-container class="cadastro-usuario" style="max-width: 1000px">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="head-title mb-2 text-center">Criar sua conta</div>
        <div cols="12" class="subtitle text-center">Precisamos de algumas informações sobre você
          gerenciar</div>
      </v-col>
    </v-row>
    <v-form ref="form">
      <v-row align="center" justify="center" class="align-center">
        <v-col cols="12">
          <div class="input-title">Seu nome completo</div>
          <v-text-field placeholder="Digite seu nome completo" outlined v-model="usuario.nome" :rules="[
            () => $v.usuario.nome.required || 'Campo obrigatório',
            () => $v.usuario.nome.minLength || 'Nome deve ter pelo menos 3 caracteres',
          ]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="input-title">Seu e-mail</div>
          <v-text-field placeholder="Digite um e-mail para sua conta" outlined v-model="usuario.email" :rules="[
            () => $v.usuario.email.required || 'Campo obrigatório',
            () => $v.usuario.email.email || 'E-mail inválido',
          ]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="input-title">Sua senha</div>
          <v-text-field placeholder="Digite uma senha para sua conta" outlined v-model="usuario.senha"
            :type="!showPassword ? 'password' : 'text'" @click:append="showPassword = !showPassword"
            :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[
              () => $v.usuario.senha.required || 'Campo obrigatório',
              () => $v.usuario.senha.minLength || 'Senha deve ter pelo menos 6 caracteres',
            ]"></v-text-field>

        </v-col>
        <v-col cols="12">
          <div class="input-title">Qual o seu cargo na empresa?</div>
          <v-text-field placeholder="Digite seu cargo na empresa" outlined v-model="usuario.cargo"
            :rules="[() => $v.usuario.cargo.required || 'Campo obrigatório']"></v-text-field>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn color="primary" @click="nextStep" :disabled="loading" rounded x-large>
            <span class="capitalize">Seguir</span>
            <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import cadastroService from '@/services/cadastro'
import cadastroMixin from './cadastroMixin'

export default {
  mixins: [validationMixin, cadastroMixin],
  validations: {
    usuario: {
      nome: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      senha: {
        required,
        minLength: minLength(6),
      },
      cargo: {
        required,
      },
    },
  },
  data() {
    return {
      usuario: {
        nome: null,
        email: null,
        senha: null,
        cargo: null
      },
      loading: false,
      showPassword: false,
    }
  },
  computed: {
    ...mapGetters('cadastro', ['getUsuario']),
  },
  methods: {
    ...mapMutations('cadastro', ['setUsuario']),

    async nextStep() {
      if (!this.$refs.form.validate()) return

      this.loading = true
      try {
        await cadastroService.verificaEmail(this.usuario.email)
        this.setUsuario(this.usuario)
        this.actualStepWrapper = 2
        this.$router.push({ name: 'cadastro-organizacao' })
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      } finally {
        this.loading = false
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 1
    this.usuario = { ...this.getUsuario }
  },
}
</script>
<style scoped></style>
