<template>
  <v-container style="max-width: 1000px">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="head-title mb-2 text-center">Leia o contrato abaixo e aceite o mesmo para continuar</div>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="8">
        <v-alert v-if="showEdgeAdvice" type="warning" :value="true">
          Este navegador não suporta a assinatura do contrato, utilize o Google Chrome ou o Mozilla Firefox
        </v-alert>
      </v-col>
      <v-col cols="10" class="text-center">Informações do contrato</v-col>
      <v-col cols="10" class="bg-gray">
        <div v-if="loading" class="text-center">
          <v-progress-circular :value="20" indeterminate></v-progress-circular>
        </div>
        <div class="contrato-container" @scroll="handleScroll">
          <ContratoClausulas />
          <!-- <ResumoContrato :plano="plano" /> -->
          <ContratoAssinatura :assinante="getUsuario" />
        </div>
      </v-col>
      <v-col cols="10" class="text-right">
        <small class="grey--text mr-8">Role o scroll até o final do contrato para prosseguir</small>
        <v-btn color="primary" @click="nextStep" :disabled="disableBtn || loading" :loading="loading" rounded x-large>
          <span class="capitalize">Finalizar</span>
          <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cadastroService from '@/services/cadastro'
import cadastroMixin from './cadastroMixin'
import ContratoClausulas from './contrato/ContratoClausulas'
import ContratoAssinatura from './contrato/ContratoAssinatura'

export default {
  components: {
    ContratoClausulas,
    ContratoAssinatura,
  },
  mixins: [cadastroMixin],
  data: () => ({
    confirmarLeitura: false,
    loading: false,
    disableBtn: true,
    showEdgeAdvice: false,
  }),
  computed: {
    ...mapGetters('cadastro', ['getCadastro', 'getUsuario']),
  },
  methods: {
    ...mapActions('cadastro', ['finalizaCadastro']),

    async nextStep() {
      this.loading = true
      try {
        const params = {
          ...this.getCadastro,
          token: decodeURIComponent(this.$route?.params?.token),
        }
        const { data } = await cadastroService.finalizaCadastro(params)
        this.loading = false
        this.actualStepWrapper = 7
        this.$router.push({ name: 'cadastro-sucesso', params: { user_token: data.token } })
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      } finally {
        this.loading = false
      }
    },
    async handleScroll(el) {
      if (el.srcElement.scrollHeight - (el.srcElement.offsetHeight + el.srcElement.scrollTop) <= 1) {
        this.disableBtn = false
      }
    },
    isEdge() {
      const { userAgent } = navigator
      if (userAgent.includes('Edg/') || userAgent.includes('Edge/')) {
        return true
      }
      return false
    },
  },
  beforeMount() {
    this.actualStepWrapper = 6
  },
  mounted() {
    // this.getPlano()
    this.showEdgeAdvice = this.isEdge()
  },
}
</script>
<style scoped>
.contrato-container {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding: 5px; */
}

.gap {
  gap: 1rem;
}

.bg-gray {
  background-color: rgb(235, 232, 232);
}
</style>
