import Axios from 'axios'
import { extractOnlyNumbers } from '@/utils/formatter'

const URL = '/cadastro-afiliado'
const buildCadastro = cadastro => ({
  ...cadastro,
  empresa: {
    ...cadastro.empresa,
    cnpj: extractOnlyNumbers(cadastro.empresa.cnpj) || null,
    cpf: extractOnlyNumbers(cadastro.empresa.cpf) || null,
  },
})

export default {
  verificaEmail: email => Axios.get(`${URL}/verifica-email?email=${email}`),
  verificaEmpresa: (cpfCnpj, validarCnpj) => Axios.get(`${URL}/verifica-empresa?cpfCnpj=${extractOnlyNumbers(cpfCnpj)}&validarCnpj=${validarCnpj}`),
  finalizaCadastro: data => Axios.post(`${URL}`, buildCadastro(data)),
  getSegmentos: () => Axios.get('segmentos'),
  getPlanos: token => Axios.get(`${URL}/get-planos/${token}`),
  buscaCidades: estado =>
    Axios.get('cidades', {
      params: { uf: estado },
    }),
}
