<template>
  <v-row no-gutters :class="{ 'full-height': $vuetify.breakpoint.mdAndUp }">
    <v-col cols="12" lg="3" :class="{ 'full-height': $vuetify.breakpoint.mdAndUp }">
      <CadastroSteps v-if="$vuetify.breakpoint.mdAndUp" />
      <CadastroStepsMobile v-else />
    </v-col>
    <v-col cols="12" lg="9" class="d-flex align-center">
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-col>
  </v-row>
</template>

<script>
import CadastroSteps from '@/modules/Cadastro/steps/CadastroSteps'
import CadastroStepsMobile from '@/modules/Cadastro/steps/CadastroStepsMobile'

export default {
  components: {
    CadastroSteps,
    CadastroStepsMobile
  },
  computed: {
    paddingSteps() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'pl-0'
        case 'sm':
          return 'px-3'
        case 'md':
          return 'pl-10'
        case 'lg':
          return 'pl-10'
        case 'xl':
          return 'pl-10'
        default:
          return 'pl-10'
      }
    },
  },
  methods: {
    goTo(step) {
      this.showLoading()
      this.$router.push({ name: step })
      this.hideLoading()
    },
  },
}
</script>
<style scoped>
.full-height {
  height: 100% !important;
}
</style>
