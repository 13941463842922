import { mapMutations, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('cadastro', ['actualStep']),
    actualStepWrapper: {
      get() {
        return this.actualStep
      },
      set(value) {
        this.setActualStep(value)
      },
    },
  },
  methods: {
    ...mapMutations('cadastro', ['setActualStep']),
  },
}
