<template>
  <v-row no-gutters align="center" justify="center" class="fill-height py-15 stepper-view">
    <v-col cols="12">
      <v-row no-gutters justify="center">
        <v-col cols="12">
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" class="head-title mb-2 text-center">Sucesso, sua conta foi criada!</v-col>
            <v-col cols="12" class="subtitle text-center">Agora você pode gerenciar a saúde de seus colaboradores de forma
              fácil e rápida</v-col>
            <v-col cols="12" class="subtitle text-center">Os detalhes da sua conta foram enviado no seu email</v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" class="mt-10" v-if="!showAdvice">
        <v-btn color="primary" rounded x-large class="elevation-12" @click="nextStep()" :loading="loading">
          <span class="capitalize">Entrar</span>
          <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </v-row>
      <v-row no-gutters justify="center" class="mt-10" v-if="showAdvice">
        <v-alert type="info" :value="true" outlined text>
          Você já pode fechar essa página
        </v-alert>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cadastroMixin from './cadastroMixin'

export default {
  mixins: [cadastroMixin],
  data: () => ({
    loading: false,
    showAdvice: false
  }),
  computed: {
    ...mapGetters('cadastro', ['getUsuario']),
  },
  methods: {
    ...mapActions('autenticacao', ['login']),
    ...mapActions('cadastro', ['reset']),

    nextStep() {
      this.loading = true
      try {
        this.reset()
        const url = `${process.env.VUE_APP_PLATAFORMA_URL}/sso-auth/${encodeURIComponent(this.$route.params.user_token)}`
        window.open(url, '_blank')
        this.showAdvice = true
      } catch (error) {
        console.log(error)
        this.showErrorOnSnackbar(error.response.data.message)
      } finally {
        this.loading = false
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 7
  },
}
</script>
