var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"cadastro-usuario",staticStyle:{"max-width":"1000px"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"head-title mb-2 text-center"},[_vm._v("Criar sua conta")]),_c('div',{staticClass:"subtitle text-center",attrs:{"cols":"12"}},[_vm._v("Precisamos de algumas informações sobre você gerenciar")])])],1),_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"align-center",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-title"},[_vm._v("Seu nome completo")]),_c('v-text-field',{attrs:{"placeholder":"Digite seu nome completo","outlined":"","rules":[
          () => _vm.$v.usuario.nome.required || 'Campo obrigatório',
          () => _vm.$v.usuario.nome.minLength || 'Nome deve ter pelo menos 3 caracteres',
        ]},model:{value:(_vm.usuario.nome),callback:function ($$v) {_vm.$set(_vm.usuario, "nome", $$v)},expression:"usuario.nome"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-title"},[_vm._v("Seu e-mail")]),_c('v-text-field',{attrs:{"placeholder":"Digite um e-mail para sua conta","outlined":"","rules":[
          () => _vm.$v.usuario.email.required || 'Campo obrigatório',
          () => _vm.$v.usuario.email.email || 'E-mail inválido',
        ]},model:{value:(_vm.usuario.email),callback:function ($$v) {_vm.$set(_vm.usuario, "email", $$v)},expression:"usuario.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-title"},[_vm._v("Sua senha")]),_c('v-text-field',{attrs:{"placeholder":"Digite uma senha para sua conta","outlined":"","type":!_vm.showPassword ? 'password' : 'text',"append-icon":!_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
            () => _vm.$v.usuario.senha.required || 'Campo obrigatório',
            () => _vm.$v.usuario.senha.minLength || 'Senha deve ter pelo menos 6 caracteres',
          ]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.usuario.senha),callback:function ($$v) {_vm.$set(_vm.usuario, "senha", $$v)},expression:"usuario.senha"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-title"},[_vm._v("Qual o seu cargo na empresa?")]),_c('v-text-field',{attrs:{"placeholder":"Digite seu cargo na empresa","outlined":"","rules":[() => _vm.$v.usuario.cargo.required || 'Campo obrigatório']},model:{value:(_vm.usuario.cargo),callback:function ($$v) {_vm.$set(_vm.usuario, "cargo", $$v)},expression:"usuario.cargo"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading,"rounded":"","x-large":""},on:{"click":_vm.nextStep}},[_c('span',{staticClass:"capitalize"},[_vm._v("Seguir")]),_c('v-icon',{attrs:{"size":"20","right":""}},[_vm._v("mdi-chevron-right-circle-outline")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }