<template>
  <div elevation="0" color="#fff" class="header container">
    <div>
      <img width="140px" src="@/assets/logo-azul.png" alt="Logo limer" />
    </div>
    <v-spacer></v-spacer>
    <v-btn :width="$vuetify.breakpoint.mdAndUp ? '263px' : '50px'" height="50px" class="button whatsapp-button mr-4"
      @click="goToWhatsapp()" outlined rounded large elevation="0" color="#5ac34f" dark>
      <v-icon color="#5ac34f" :class="{ 'ml-4': $vuetify.breakpoint.mdAndUp }" size="1.8em">mdi-whatsapp</v-icon>
      <div class="whatsapp-button-text button-text" v-if="$vuetify.breakpoint.mdAndUp">Falar com um analista</div>
    </v-btn>
    <v-btn color="#006bf2" rounded elevation="0" class="button" dark @click="openPlataforma()" height="50px"
      width="120px">
      <div class="button-text">Entrar</div>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    goToWhatsapp() {
      window.open('https://wa.me/5511961701736', '_blank')
    },
    openPlataforma() {
      window.open(process.env.VUE_APP_PLATAFORMA_URL, '_blank')
    },
  },
}
</script>

<style scoped>
.container {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 33.6px;
  padding-bottom: 33.6px;
  margin-right: auto;
  margin-left: auto;
}

.header {
  display: flex;
  align-content: center;
  align-items: center;
}

.button {
  text-transform: lowercase;
  width: 100px;
}

.whatsapp-button {
  background-color: #fff;
}

.button:hover {
  transform: scale(1.05);
}

.button-text::first-letter {
  text-transform: uppercase !important;
}

.button-text {
  font-size: 0.8rem;
}

.whatsapp-button-text {
  margin-left: 0.2rem;
}

@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1390px;
    max-width: 100%;
  }
}
</style>
