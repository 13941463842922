import Vue from 'vue';
import Vuex from 'vuex';
  import VuexPersistence from 'vuex-persist'
import cadastro from './modules/cadastro'
import layout from './modules/layout'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  modules: ['cadastro']
})

export default new Vuex.Store({
  modules: {
    layout,
    cadastro
  },
  plugins: [vuexLocal.plugin]
});
