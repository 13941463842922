<template>
  <div class="plano-item" :class="{ checked: isSelected }" @click.prevent="selectPlan">
    <div class="plano-checkbox">
      <div class="checkbox-border" :class="{ checked: isSelected }">
        <input type="checkbox" class="checkbox-round" label="label" :checked="isSelected" @click.stop="selectPlan" />
      </div>
    </div>
    <div class="plano-info">
      <div class="plano-nome">{{ plano.nome }}</div>
      <div class="plano-descricao" v-html="plano.descricao"></div>
    </div>
    <v-slide-x-transition mode="out-in">
      <div class="plano-preco" :key="plano.recorrencia">
        <div class="d-flex align-center" v-if="plano.preco">
          <span class="currency">R$</span>
          <span class="preco">{{ integerPrice }},</span>
          <span class="cents">{{ cents }}</span>
        </div>
        <div v-if="plano.descricaoPreco" class="preco-descricao">
          {{ plano.recorrencia === 'anual' ? 'Anualmente' : 'Mensalidade' }}
        </div>
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script>
export default {
  name: 'PlanoItem',
  props: {
    plano: {
      required: true,
      type: Object,
    },
    isSelected: {
      required: true,
    },
  },
  data() {
    return {
      value: true,
    }
  },
  computed: {
    getPreco() {
      // if (this.plano.recorrencia && this.plano.recorrencia === 'anual') {
      //   const precoAnual = this.plano.preco * 12
      //   const precoAnualComDesconto = precoAnual - precoAnual * 0.2

      //   return precoAnualComDesconto.toFixed()
      // }
      return this.plano.preco
    },
    cents() {
      const cents = (this.getPreco - Math.floor(this.getPreco)).toString()
      let value = cents.split('.')[1]
      // console.log(value.substring(0, 2))
      value = value ? value.substring(0, 2) : 0
      return value.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    },
    integerPrice() {
      return Math.floor(this.getPreco)
    },
  },
  methods: {
    selectPlan() {
      if (!this.isSelected) {
        this.$emit('update-selected-plano', this.plano)
      }
    },
  },
}
</script>

<style scoped>
.plano-item {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 90%;
  height: 120px !important;
  padding: 20px 10px;
  border: 2px solid;
  border-color: #d8d8e6;
  border-radius: 30px;
  cursor: pointer;
  text-align: left;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.checkbox-round {
  width: 1em;
  height: 1em;
  background-color: #d8d8e6;
  border-radius: 50%;
  vertical-align: middle;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  padding: 5px;
  cursor: pointer;
}
.checkbox-border {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  border: 3px solid #d8d8e6;
  border-color: #d8d8e6 !important;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #006bf2;
  border-color: #006bf2 !important;
}
.checked {
  border-color: #006bf2 !important;
}
.plano-nome {
  font-size: 1.7rem;
  color: #006bf2;
  font-weight: 700;
}
.plano-descricao {
  color: #9293ab;
  font-size: 0.8rem;
}
.plano-item:hover {
  transform: scale(1.04);
}
.plano-info {
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: left;
}
.plano-checkbox {
  margin-right: 15px;
  width: 10%;
}
.plano-preco {
  width: 100px;
  display: flex;
  flex-direction: column;
}
.preco-descricao {
  background-color: #dbe7ff;
  color: #006bf2;
  border-radius: 16px;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 900;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.preco {
  margin-left: 5px;
  font-size: 2rem;
  color: #006bf2;
  font-weight: 900;
  /* width: 40px; */
}
.cents {
  margin-bottom: 1rem;
  margin-left: 3px;
  color: #006bf2;
}
.currency {
  font-size: 0.8rem;
  color: #898aa3;
}
@media (max-width: 768px) {
  .plano-item {
    width: 100% !important;
  }
}
</style>
