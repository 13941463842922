<template>
  <v-slide-x-transition mode="out-in" class="mx-auto">
    <div class="beneficios-plano" v-if="plano" :key="plano.slug">
      <div class="font-weight-bold mb-6">
        {{ plano.title }}
      </div>
      <div>
        <div class="font-weight-bold mb-6">Incluso</div>
        <div v-for="item in plano.beneficios" :key="item.slug" class="mb-1">
          <v-icon color="#fff" size="20" class="ml-3 mr-2">mdi-checkbox-marked-circle-outline</v-icon>
          <span class="font-weight-light">{{ item }}</span>
        </div>
      </div>
      <div class="font-weight-bold mt-13">
        {{ plano.footer }}
      </div>
      <div class="action">
        <v-btn
          rounded
          color="white"
          class="buy-now"
          :class="{ 'justify-space-between': $vuetify.breakpoint.smAndDown }"
          x-large
          @click.prevent="goToCadastro()"
        >
          <div class="hire-button">
            <b>CONTRATAR AGORA</b>
            <v-icon large>mdi-chevron-right-circle-outline</v-icon>
          </div>
        </v-btn>
      </div>
    </div>
  </v-slide-x-transition>
</template>

<script>
export default {
  name: 'BeneficiosPlano',
  props: {
    plano: {
      required: true,
    },
  },
  methods: {
    goToCadastro() {
      this.$emit('go-to-cadastro')
    },
  },
}
</script>

<style scoped>
.beneficios-plano {
  display: flex;
  flex-direction: column;
  background-color: #006bf2;
  padding: 2rem 1.5rem;
  border-radius: 16px;
  color: #fff;
  max-width: 90%;
  text-align: left;
  width: 100%;
}
.button-icon-right {
  position: absolute;
  right: 0;
}
.buy-now {
  color: #006bf2 !important;
  font-size: 0.9rem !important;
  font-weight: 900 !important;
  display: flex;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.beneficios-plano >>> .v-btn:not(.v-btn--round).v-size--x-large {
  width: 280px !important;
}
.action {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}
.hire-button {
  display: flex;
  justify-content: space-between;
  justify-items: space-between;
  align-items: center;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.buy-now:hover {
  transform: scale(1.04);
}
</style>
