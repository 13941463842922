import Vue from 'vue'
import VueRouter from 'vue-router'
import Cadastro from '@/views/Cadastro'
import Planos from '@/views/Planos'
import NotFound from '@/views/NotFound'
import CadastroContrato from '@/modules/Cadastro/CadastroContrato'
import CadastroEmpresa from '@/modules/Cadastro/CadastroEmpresa'
import CadastroUsuario from '@/modules/Cadastro/CadastroUsuario'
import CadastroFaturamento from '@/modules/Cadastro/CadastroFaturamento'
import CadastroOrganizacao from '@/modules/Cadastro/CadastroOrganizacao'
import CadastroSucesso from '@/modules/Cadastro/CadastroSucesso'
import CadastroSegmentos from '@/modules/Cadastro/CadastroSegmentos'
import store from '@/store/index'

Vue.use(VueRouter)

function currentStep() {
  return store.state.cadastro.actualStep
}

const routes = [
  {
    path: '/cadastro-afiliado/:token',
    component: Planos,
    meta: {
      public: true,
    },
  },
  {
    path: '/cadastro/:token',
    component: Cadastro,
    meta: { public: true },
    children: [
      {
        path: 'usuario',
        name: 'cadastro-usuario',
        component: CadastroUsuario,
        meta: { step: 1 },
      },
      {
        path: 'organizacao',
        name: 'cadastro-organizacao',
        component: CadastroOrganizacao,
        meta: { step: 2 },
      },
      {
        path: 'empresa',
        name: 'cadastro-empresa',
        component: CadastroEmpresa,
        meta: { step: 3 },
      },
      {
        path: 'segmento',
        name: 'cadastro-segmentos',
        component: CadastroSegmentos,
        meta: { step: 4 },
      },
      {
        path: 'aturamento',
        name: 'cadastro-faturamento',
        component: CadastroFaturamento,
        meta: { step: 5 },
      },
      {
        path: 'contrato',
        name: 'cadastro-contrato',
        component: CadastroContrato,
        meta: { step: 6 },
      },
      {
        path: 'sucesso/:user_token',
        name: 'cadastro-sucesso',
        component: CadastroSucesso,
        meta: { step: 7 },
      },
    ],
  },
  {
    path: '/error',
    component: NotFound,
    name: 'error',
    meta: {
      public: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.params.token && to.name !== 'error') {
    next({ name: 'error' })
    return
  }
  if (to.meta.step > currentStep()) {
    next({ name: 'error' })
  }
  next()
})

export default router
