<template>
  <v-snackbar
      :value="value"
      @input="hideErrorOnSnackbar"
      color="error"
      :timeout="6000"
      multi-line
      top
    >
    <v-container class="ma-0 pa-0">
      <v-row justify="space-between" align="center">
        <v-col class="font-weight-bold">
          {{ message }}
        </v-col>
        <v-col cols="auto" class="mr-1">
          <v-btn
            class="text-none"
            text
            @click.stop="hideErrorOnSnackbar"
          >
            FECHAR
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
  export default {
    props: {
      message: String,
      value: Boolean
    }
  }
</script>
