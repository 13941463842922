<template>
  <div class="selecionar-planos">
    <v-row>
      <v-col cols="12" xl="6" lg="6">
        <div class="d-flex flex-column text-center">
          <div class="plano-selecionado">
            <v-slide-x-transition mode="out-in">
              <div v-if="planoSelecionado" :key="planoSelecionado.nome">
                Plano <span class="nome-plano">{{ planoSelecionado.nome }}</span> selecionado
              </div>
            </v-slide-x-transition>
          </div>
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="d-flex flex-column"
            :class="{ 'align-end': $vuetify.breakpoint.mdAndUp }"
          >
            <BeneficiosPlano :plano="planoSelecionado" @go-to-cadastro="goToCadastro" />
          </div>
        </div>
      </v-col>
      <v-col cols="12" xl="6" lg="6">
        <div
          class="d-flex flex-column text-center align-center"
          :class="{ 'align-center': $vuetify.breakpoint.smAndDown }"
        >
          <div class="plano-recorrencia">
            <!-- <v-expand-transition>
              <div>
                Mensal
                <v-icon class="mx-2" :color="switchColor" @click="updatePlanoRecorrencia">
                  {{ isAnual ? 'mdi-toggle-switch-outline' : 'mdi-toggle-switch-off-outline' }}
                </v-icon>
                Anual *20%off
              </div>
            </v-expand-transition> -->
          </div>
          <div class="d-flex flex-column align-center">
            <PlanosList
              :planos="planos"
              @update-selected-plano="updateSelectedPlano"
              :planoSelecionado="planoSelecionado"
              @go-to-cadastro="goToCadastro"
            />
            <v-expand-transition>
              <v-alert outlined color="error" :value="showValidateError">
                É necessário escolher um plano para avançar
              </v-alert>
            </v-expand-transition>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { isNil } from 'lodash'
import BeneficiosPlano from './BeneficiosPlano'
import PlanosList from './PlanosList'

export default {
  name: 'SelecionarPlanos',
  components: { BeneficiosPlano, PlanosList },
  props: {
    colaboradores: {
      required: true,
    },
  },
  data() {
    return {
      planos: [
        // {
        //   nome: 'Plus',
        //   slug: 'plus',
        //   title: 'Plano Plus, com esse plano você paga apenas os exames e documentos que consumir',
        //   descricao: 'Pague apenas o que consumir na plataforma',
        //   preco: null,
        //   beneficios: [
        //     'Acesso a rede de atendimento',
        //     'Agendamento de exames',
        //     'Ambiente do colaborador',
        //     'Download do XML dos Layouts s-2220 e s-2240',
        //   ],
        //   footer: 'Nesse plano o valor de sua fatura varia de acordo com o consumo de exames na plataforma',
        // },
        {
          nome: 'Smart',
          slug: 'smart',
          title: 'Plano Smart, com esse plano você acessa as principais funcionalidades da plataforma',
          descricao: 'Pague uma mensalidade e obtenha mais recursos na plataforma e exames a partir de R$ 59,90',
          preco: 490.0,
          descricaoPreco: 'Anualmente',
          recorrencia: 'anual',
          beneficios: [
            'Recursos do plano plus',
            'PCMSO e PGR online',
            'E-social mensageria s-2220 e s-2240',
            'Gestão de atestados médicos',
            'Médico coordenador PCMSO',
            'Telemedicina',
          ],
          footer: 'Obs.: Exames complementares não estão inclusos no plano, caso precise, pague o excedente',
        },
        // {
        //   nome: 'Mrr',
        //   slug: 'mrr',
        //   title: 'Plar Mrr, com esse plano você acessa todas as funcionalidades da plataforma',
        //   descricao: 'Todos os recursos da plataforma e <br>muito mais',
        //   preco: 7.9,
        //   descricaoPreco: 'Per capta',
        //   beneficios: [
        //     'Recursos do plano Smart',
        //     'Suporte técnico dedicado',
        //     'Telemedicina ilimitada',
        //     'Controle sobre o valor pago',
        //   ],
        //   footer: 'Obs.: Exames complementares não estão inclusos no plano, caso precise, pague o excedente',
        // },
      ],
      planoSelecionado: null,
      showValidateError: false,
      empresa: {
        colaboradores: null,
      },
      faturamento: {
        plano: null,
        recorrencia: null,
      },
      isAnual: true,
    }
  },
  computed: {
    ...mapGetters('cadastro', ['getEmpresa', 'getFaturamento']),
    switchColor() {
      return this.planoSelecionado && this.planoSelecionado.slug === 'smart' ? '#2C05FF' : '#d8d8e6'
    },
  },
  methods: {
    ...mapMutations('cadastro', ['setEmpresa', 'setFaturamento']),
    updateSelectedPlano(planoSelecionado) {
      this.planoSelecionado = planoSelecionado
    },
    updatePlanoRecorrencia() {
      if (this.planoSelecionado && this.planoSelecionado.slug === 'smart') {
        if (this.planoSelecionado.recorrencia === 'anual') {
          this.planoSelecionado.recorrencia = 'mensal'
          this.isAnual = false
        } else {
          this.planoSelecionado.recorrencia = 'anual'
          this.isAnual = true
        }
      }
    },
    validateForm() {
      if (isNil(this.planoSelecionado)) {
        this.showValidateError = true
        return false
      }
      return true
    },
    goToCadastro() {
      if (this.validateForm()) {
        this.faturamento.plano = this.planoSelecionado.slug
        this.empresa.colaboradores = this.colaboradores
        this.faturamento.recorrencia = this.planoSelecionado.recorrencia ? this.planoSelecionado.recorrencia : null
        this.setFaturamento(this.faturamento)
        this.setEmpresa(this.empresa)
        this.$router.push({ path: '/cadastro' })
      }
    },
  },
  mounted() {
    this.empresa = { ...this.getEmpresa }
    this.faturamento = { ...this.getFaturamento }
  },
}
</script>

<style scoped>
.selecionar-planos {
  margin-top: 40px;
}
.nome-plano {
  color: #2619ff;
}
.plano-recorrencia {
  color: #747693;
  margin-bottom: 1.5rem;
}
.plano-selecionado {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  margin-left: 1vw;
}
</style>
