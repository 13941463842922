const getDefaultState = () => ({
  resetCadastro: false,
  actualStep: 1,
  usuario: {},
  id_plano_padrao: null,
  empresa: {
    tipo_empresa: 'pessoa_juridica',
    cpf: null,
    cnpj: null,
    pcmso: null,
    possuiPCMSO: false,
    cnae_principal_codigo: null,
    endereco_bairro: null,
    endereco_cep: null,
    endereco_id_cidade: null,
    endereco_numero: null,
    endereco_rua: null,
    endereco_uf: null,
    razao_social: null,
    segmento: null,
    qtde_colaboradores: 1,
  },
  organizacao: {
    nome: null
  },
  faturamento: {
    email: null,
    responsavel: null,
    dia: null,
  },
})

const state = getDefaultState()

const mutations = {
  resetState: state => Object.assign(state, getDefaultState()),
  setActualStep: (state, step) => (state.actualStep = step),
  setUsuario: (state, usuario) => (state.usuario = { ...usuario }),
  setEmpresa: (state, empresa) => (state.empresa = { ...state.empresa, ...empresa }),
  setQtdeColaboradores: (state, qtde_colaboradores) => (state.empresa.qtde_colaboradores = qtde_colaboradores),
  setPlanoPadrao: (state, id_plano_padrao) => (state.id_plano_padrao = id_plano_padrao),
  setOrganizacao: (state, organizacao) => (state.organizacao = { ...organizacao }),
  setFaturamento: (state, faturamento) => (state.faturamento = faturamento),
}

const getters = {
  getCadastro: state => state,
  getResetCadastro: state => state.resetCadastro,
  getUsuario: state => state.usuario,
  getEmpresa: state => state.empresa,
  getQtdeColaboradores: state => state.empresa.qtde_colaboradores,
  getOrganizacao: state => state.organizacao,
  getFaturamento: state => state.faturamento
}

const actions = {
  reset: ({ commit }) => {
    commit('resetState')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
