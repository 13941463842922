<template>
  <v-container style="max-width: 1000px">
    <v-row align="center" justify="center" class="head">
      <v-col cols="12">
        <div class="head-title mb-2 text-center">Faturamento</div>
        <div cols="12" class="subtitle text-center">recisamos de algumas informações sobre você</div>
      </v-col>
    </v-row>
    <v-form ref="form">
      <v-row>
        <v-col cols="12">
          <div class="input-title">E-mail faturamento</div>
          <v-text-field placeholder="Digite o e-mail de faturamento" outlined v-model="faturamento.email" :rules="[
            () => $v.faturamento.email.required || 'Campo obrigatório',
            () => $v.faturamento.email.email || 'E-mail inválido',
          ]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="input-title">Nome do responsável pelas faturas</div>
          <v-text-field placeholder="Digite o nome do responsável" outlined v-model="faturamento.responsavel" :rules="[
            () => $v.faturamento.responsavel.required || 'Campo obrigatório',
            () => $v.faturamento.responsavel.minLength || 'Nome deve ter pelo menos 3 caracteres',
          ]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="input-title">Data para emissão da fatura</div>
          <v-select placeholder="Escolha o melhor dia" outlined v-model="faturamento.dia" :items="faturamentoItems" :rules="[
            () => $v.faturamento.dia.required || 'Campo obrigatório',
            () => $v.faturamento.dia.numeric || 'Nome deve ter pelo menos 3 caracteres',
          ]"></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-spacer></v-spacer>
        <v-btn color="primary" rounded x-large class="elevation-12" @click="nextStep()">
          <span class="capitalize">Seguir</span>
          <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, numeric, minLength } from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'
import cadastroMixin from './cadastroMixin'

export default {
  mixins: [validationMixin, cadastroMixin],
  validations: {
    faturamento: {
      responsavel: { required, minLength: minLength(3) },
      dia: {
        required,
        numeric,
      },
      email: {
        required,
        email,
      },
    },
  },
  data() {
    return {
      faturamentoItems: [10, 25],
      faturamento: {
        responsavel: null,
        dia: null,
        email: null,
      },
    }
  },
  computed: {
    ...mapGetters('cadastro', ['getFaturamento']),
  },
  methods: {
    ...mapMutations('cadastro', ['setFaturamento']),

    async nextStep() {
      if (!this.$refs.form.validate()) return

      this.setFaturamento(this.faturamento)
      this.actualStepWrapper = 6
      this.$router.push({ name: 'cadastro-contrato' })
    },
  },
  beforeMount() {
    this.actualStepWrapper = 5
    this.faturamento = { ...this.getFaturamento }
  },
}
</script>
