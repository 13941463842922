<template>
 <div class="empresa-plano-card">
  <div class="title">
   {{ plano.tipo }}
  </div>
  <div class="description">
   {{ plano.descricao || descriptions[plano.tipo] }}
  </div>
  <div class="preco">
   <div class="preco-plano" v-if="plano.preco_plano">
    <div class="simbolo">R$</div>
    <div class="valor">
     {{ preco }}
    </div>
    <div class="price-description">
     {{ priceDescription[plano.tipo] }}
    </div>
   </div>
   <div class="sob-consulta" v-else>Sob consulta</div>
  </div>
  <div class="text-center my-2">
   <v-btn color="#2a04ff" @click="$emit('on-select-plan', plano.id)" class="select-plan" rounded outlined>
    <b>Selecionar plano</b>
   </v-btn>
  </div>
  <v-tooltip top v-if="recursos.length > 0">
   <template v-slot:activator="{ on }">
    <div v-on="on" class="recursos-list">
     <div class="recursos-title">Recursos</div>
     <div v-for="(recurso, index) in recursos" :key="index" class="d-flex flex-row mb-2">
      <v-icon size="20" class="icon-list" color="#2a04ff">mdi-check-decagram-outline</v-icon>
      <div class="recurso-item d-flex flex-column" v-if="recurso.faturar">
       <div class="d-flex flex-row">
        <div class="recurso-nome">
         {{ recurso.nome }}
        </div>
        &nbsp;-&nbsp;
        <span class="font-weight-bold">{{ recurso.preco | preco }}</span>
        <span class="font-weight-bold">
         &nbsp;
         ({{ recurso.recorrencia }})
        </span>
       </div>
      </div>
      <div class="recurso-item d-flex" v-else>
       <div class="recurso-nome">{{ recurso.nome }}</div>
      </div>
     </div>
    </div>
   </template>
   <div class="recursos-list" v-if="recursos.length > 0">
    <div v-for="(recurso, index) in recursos" :key="index" class="d-flex flex-row mb-2">
     -&nbsp;
     <div class="recurso-item d-flex flex-column" v-if="recurso.faturar">
      <div>
       {{ recurso.nome }}
       <span class="font-weight-bold">{{ recurso.preco | preco }}</span>
       <span class="font-weight-bold">
        ({{ recurso.recorrencia }})
       </span>
      </div>
     </div>
     <div class="recurso-item d-flex" v-else>
      <b>(Incluso)&nbsp;-</b>{{ recurso.nome }}
     </div>
    </div>
   </div>
  </v-tooltip>

 </div>
</template>
<script>
export default {
 props: {
  plano: {
   required: true,
   type: Object,
  },
 },
 data() {
  return {
   descriptions: {
    assinatura:
     'Exames complementares não estão inclusos no plano. Pague esse valor para cada assinatura contratada',
    mrr:
     'Exames complementares não estão inclusos no plano. Pague esse valor para cada funcionário cadastrado',
    avulso: 'Exames clínicos ocupacionais e complementares não estão inclusos no plano. ',
   },
   priceDescription: {
    avulso: '/Exame Ocupacional',
    assinatura: '/Assinatura'
   }
  }
 },
 computed: {
  excedeuLimiteAltura() {
   // Obtenha a altura total da div de recursos
   const alturaDivRecursos = this.$el.querySelector('.recursos-list').clientHeight;
   console.log(alturaDivRecursos)
   // Verifique se a altura excede o limite
   return alturaDivRecursos > this.limiteAltura;
  },
  preco() {
   return this.plano.preco_plano ? this.plano.preco_plano.toFixed(2).replace('.', ',') : ''
  },
  recursos() {
   return this.plano.produtos ? this.plano.produtos.concat(this.plano.modulos) : []
  },
 },
}
</script>
<style lang="scss">
.empresa-plano-card {
 background-color: #d7e6fa;
 height: 500px;
 border-radius: 16px;
 padding: 15px 30px;
 display: flex;
 flex-direction: column;
 gap: 15px;
 transition: all linear 150ms;

 &:hover {
  background-color: #015dff;
  color: #fff;

  .title,
  .preco,
  .recurso-item,
  .recursos-title,
  .icon-list {
   color: #fff !important;
  }

  .select-plan {
   color: #000 !important;
   border-color: #ffbb4d !important;
   background-color: #ffbb4d !important;
  }
 }

 .btn-content {
  font-size: 0.8rem;
 }

 .title {
  text-transform: capitalize;
  font-size: 1.4rem;
  color: black;
 }

 .description {
  font-size: 0.8rem;
  height: 15%;
 }

 .preco {
  color: #2a04ff;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 10%;
  margin-top: 10px;
  user-select: none;

  .preco-plano {
   display: flex;
   flex-direction: row;
   align-items: flex-end;
  }

  .simbolo {
   font-size: 1rem;
   margin-right: 5px;
   margin-bottom: 8px;
  }

  .valor {
   font-size: 2.2rem;
  }

  .sob-consulta {
   font-size: 1.5rem;
  }
 }

 .price-description {
  font-size: 0.7rem;
  transform: translateY(10px);
  margin-left: 7px;
 }

 .recursos-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
 }

 .recurso-nome {
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 10vw;
 }

 .recurso-item {
  font-size: 0.8rem;
  color: rgb(88, 87, 87);
  margin-left: 5px;
 }

 .recursos-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: rgb(88, 87, 87);
  font-weight: bold;
 }
}
</style>
