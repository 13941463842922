<template>
  <div class="d-flex flex flex-column step-container">
    <div class="logo">
      <img class="mt-2 ml-2" width="140px" src="@/assets/logo.png" alt="Logo limer" />
    </div>
    <div class="mt-auto mb-auto ml-8">
      <transition name="slide-fade" mode="out-in">
        <div class="step-title mb-10 ml-1" :key="actualStep">{{ actualStep }} de {{ steps.length }} passos</div>
      </transition>
      <div class="steppers">
        <transition name="slide-fade" mode="out-in">
          <CadastroStepCard :step="getStepByNumber(actualStep)" :active="true" :key="actualStep" />
        </transition>
        <transition name="slide-fade" mode="out-in">
          <CadastroStepCard v-if="$route.name !== 'cadastro-sucesso'" :step="getStepByNumber(actualStep + 1)" class="mb-5"
            :key="actualStep + 1" />
        </transition>
      </div>
    </div>
    <div class="back-button" v-if="this.actualStep < 7" @click="$router.back()">
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" size="50" color="#006bf2">mdi-chevron-left</v-icon>
        </template>
        <span>Página anterior</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CadastroStepCard from '@/modules/Cadastro/steps/CadastroStepCard'
import { steps } from '@/utils/constants'

export default {
  components: {
    CadastroStepCard,
  },
  data: () => ({
    steps,
  }),
  computed: {
    offsetPixel() {
      return `-${(this.actualStep - 1) * 120}px`
    },
    ...mapState('cadastro', ['actualStep']),
  },
  methods: {
    getStepByNumber(step) {
      return this.steps.find(item => item.number === step)
    },
  },
}
</script>

<style lang="scss" scoped>
.step-title {
  font-size: 1.3rem;
}

.steppers {
  display: flex;
  flex-direction: column;
  top: 0;
  position: relative;
  transition: top 0.5s;
}

.step-container {
  background: #006bf2;
  height: 100%;
  color: #fff;
  position: fixed;
  width: 25%;
  padding: auto;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.back-button {
  position: absolute;
  top: 0;
  margin-left: 100%;
  margin-top: 10px;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  cursor: pointer;
}

.back-button:hover {
  transform: scale(1.1);
}
</style>
